import React from 'react'
import { Link, navigate } from 'gatsby'
import '../styles/bootstrap.scss'
import '../styles/style.css'

interface Props {
	path: string
}

const links: {path: string, name: string}[] = [
	{ path: '/windows', name: 'Windows' },
	{ path: '/internal-doors', name: 'Internal Doors' },
	{ path: '/contact', name: 'Contact' },
]

export default function Header(props: Props) {
	return <>
		<header className='mb-3' style={{fontSize:'1.1rem', zIndex:300}}>
			<div className='container'>
				<div className='d-flex py-2'>
					<ul className='nav flex-fill'>
						<li>
							<Link to='/' className='nav-link px-3 link-secondary fw-bold' style={{fontSize:'2.4rem', letterSpacing: '0.14rem'}}>
								KELGRAND
							</Link>
						</li>
					</ul>
					<ul className='nav mt-3'>
						{links.map(pair => <li key={pair.path}>
							<Link to={pair.path} className={`nav-link px-3 ${(props.path === pair.path || props.path === `${pair.path}/`) ? 'link-primary' : 'link-secondary'}`}>
								{pair.name}
							</Link>
						</li>)}
					</ul>
				</div>
			</div>
		</header>
	</>
}
