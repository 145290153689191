import React from 'react'
import { Link, navigate } from 'gatsby'


export default function Footer() {
	return <>
		<footer style={{
			backgroundColor: '#54595F',
		}}>
			<div className='container'>
				<div className='footer-links'>
					<Link to='/pricing'>
						Pricing
					</Link>
					<Link to='/terms'>
						Terms &amp; Conditions
					</Link>
				</div>
				<div className='footer-copyright'>
					Copyright Kelgrand 2020
				</div>
			</div>
		</footer>
	</>
}
